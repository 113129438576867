<template>
    <Dialog ref="declineDmInviteModal" header="Decline invite?" v-model:visible="display" :modal="true" :closable="true" class="decline-dm-invite-modal" appendTo="body">
        <template #footer>
            <div class="main-container">
                <Button label="Cancel" class="cancel-btn p-button-raised p-button-rounded" @click="cancelClick()" />
                <Button label="Decline" class="decline-btn p-button-raised p-button-rounded" @click="declineClick()" />
            </div>
            <div class="block-checkbox-container">
                <Checkbox inputId="block-checkbox" v-model="block" :binary="true" />
                <label for="block-checkbox" class="block-checkbox-label" @click="blockLabelClick()">Block this user</label>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';

export default {
    name: 'DeclineDmInviteModal',

    data() {
        return {
            display: false,
            block: false,
            declineDMRequest: undefined,
            blockUserRequest: undefined,
        };
    },

    components: {
        Dialog,
        Checkbox,
    },

    methods: {
        open(declineDMRequest, blockUserRequest) {
            this.declineDMRequest = declineDMRequest;
            this.blockUserRequest = blockUserRequest;
            this.block = false;
            this.display = true;
        },

        cancelClick() {
            this.display = false;
        },

        declineClick() {
            if (this.declineDMRequest) {
                this.declineDMRequest();
            }
            if (this.blockUserRequest && this.block) {
                this.blockUserRequest();
            }
            this.display = false;
        },

        blockLabelClick() {
            this.block = !this.block;
        },
    },
};
</script>

<style >
.decline-dm-invite-modal .p-checkbox-box.p-highlight:hover {
    border-color: #33cc99 !important;
    background: #33cc99 !important;
}

.decline-dm-invite-modal .p-dialog-content {
    padding: 0 !important;
}
</style>

<style scoped>
.main-container {
    width: 270px;
    display: flex;
    justify-content: space-between;
}

.block-checkbox-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-checkbox-label {
    margin-left: 8px;
    cursor: pointer;
}

.p-dialog-footer .p-button {
    font-family: 'Trebuchet MS', 'Verdana';
    font-weight: 600;
    padding: 0.75rem 2.75rem;
    border-width: 2px !important;
    margin: 0 !important;
}
.cancel-btn:active,
.cancel-btn:hover {
    color: #ffffff !important;
    background: #32364e !important;
}
.cancel-btn {
    color: #32364e !important;
    background: #ffffff !important;
    border-color: #32364e !important;
}
.decline-btn:active,
.decline-btn:hover {
    color: #ffffff !important;
    background: #e63e3e !important;
}
.decline-btn {
    color: #32364e !important;
    background: #ffffff !important;
    border-color: #e63e3e !important;
}
</style>